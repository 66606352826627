<template>
    <section id="portfolio" class="portfolio sections-bg">
        <div class="container" data-aos="fade-up">

            <SectionHeader v-if="!home" :title="title" :description="description" :breadcrumbs="breadcrumbs" />
            <div v-if="home"><h1 class="text-center custom-font-color">Explore our {{ description }}</h1>
            <hr class="custom-font-color hr-size"></div>
            <br>

            <div class="portfolio-isotope" data-portfolio-layout="masonry" data-portfolio-sort="original-order"
                data-aos="fade-up" data-aos-delay="100">



                <div v-if="products && (search || home)" class="row gy-4 portfolio-container">
                    <div class="col-xl-2 col-md-3 col-sm-6 col-6 card-item" v-for="product in products" :key="product.id"
                        @click="navigate(`/products/${product.id}`)">
                        <div class="portfolio-wrap">
                            <div class="cover-wrap">
                                <img :src="formatImage(product.coverImage)" class="cover-wrap-img" alt="Category Image">
                            </div>
                            <div class="portfolio-info">
                                <h4>{{ product.name }}</h4>
                                <p class="description">{{ truncateDescription(product.description) }}</p>

                                <!--<v-btn rounded="xl" elevation="5" class="w-100 mt-3">Quotation</v-btn>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="products" class="row gy-4 portfolio-container">
                    <div class="col-xl-2 col-md-3 col-sm-6 col-6 card-item" v-for="product in products" :key="product.id"
                        @click="navigate(`/products/${product.id}/${id}`)">
                        <div class="portfolio-wrap">
                            <div class="cover-wrap">
                                <img :src="formatImage(product.coverImage)" class="cover-wrap-img" alt="Category Image">
                            </div>
                            <div class="portfolio-info">
                                <h4>{{ product.name }}</h4>
                                <p class="description">{{ truncateDescription(product.description) }}</p>

                                <!--<v-btn rounded="xl" elevation="5" class="w-100 mt-3">Quotation</v-btn>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="categories" class="row gy-4 portfolio-container">
                    <div class="col-xl-2 col-md-3 col-sm-6 col-6 card-item" v-for="category in categories" :key="category.id"
                        @click="navigate(`/categories/${category.id}`)">
                        <div class="portfolio-wrap">
                            <div class="cover-wrap">
                                <img :src="formatImage(category.coverImage)" class="cover-wrap-img" alt="Category Image">
                            </div>
                            <div class="portfolio-info">
                                <h4 class="text-center">{{ category.name }}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pagination-container">
                    <v-pagination rounded="xl" class="mt-5" v-model="page" :length="pages"
                        @click="updatePanel"></v-pagination>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
import SectionHeader from '@/components/headers/SectionHeader';
import { useCategoryStore } from '@/stores/categoryStore';
import router from '@/router';

export default {
    name: 'CategorySection',
    emits: ['updatePanel'],
    components: {
        SectionHeader
    },
    props: {
        id: {
            type: String
        },
        home: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Categories'
        },
        breadcrumbs: {
            type: Array
        },
        description: {
            type: String,
            default: 'Discover our categories'
        },
        products: {
            type: Array
        },
        categories: {
            type: Array
        },
        pages: {
            type: Number,
            default: 1
        }
    },
    data() {
        const categoryStore = useCategoryStore();

        return {
            categoryStore,
            page: 1
        }
    },
    async mounted() {
        if (this.home) await this.categoryStore.fetchHome();
    },
    methods: {
        navigate(link) {
            router.push(link);
        },
        formatImage(img) {
            return `data:image/png;base64,${img}`;
        },
        updatePanel() {
            this.$emit('updatePanel', {
                page: this.page
            });
        },
        truncateDescription(description) {
            // Check if description length is greater than 125 characters
            if (description && description.length > 125) {
                // Truncate description to 125 characters and append '...' at the end
                return description.slice(0, 125) + '...';
            } else {
                // Return the original description if it's already less than or equal to 125 characters
                return description;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.pagination-container {
    margin-top: 50px;

    .v-pagination {
        color: black;
    }
}

.v-btn {
    background: var(--primary-purple);
    color: white;
}

.v-btn:hover {
    background: transparent;
    color: #652C7F;
    border: 2px solid var(--primary-purple);
}

#moreBtn {
    width: 170px;
    color: var(--primary-purple);
    background-color: transparent;
    border: 2px solid var(--primary-purple);
}

#moreBtn:hover {
    color: white;
    background-color: var(--primary-purple);
}

.description {
    height: 90px;
    overflow: hidden;
}

.card-item {
    cursor: pointer;
}

.portfolio .portfolio-wrap {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    border-radius: 25px;
    background-color: #fff;
}

.portfolio .portfolio-wrap img {
    height: 100%;
    height: 180px;
    width: 100%;
    transition: 0.3s;
    position: relative;
    object-fit: cover;
}

.portfolio .portfolio-wrap .portfolio-info {
    padding: 25px 20px;
    position: relative;
    background-color: #fff;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info h4 a {
    color: var(--color-default);
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info h4 a:hover {
    color: var(--primary-purple);
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: #6c757d;
    font-size: 14px;
    margin-bottom: 0;
}

.portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
}

.cover-wrap {
    height: 180px;
    overflow: hidden;
}

.cover-wrap-img {
    margin-left: auto;
    margin-right: auto;
max-width: 100% !important;
width: unset !important;
height: unset !important;
max-height: 100% !important;
}



@media (max-width: 350px) {

.cover-wrap {
    height: 130px;
}
}

@media (min-width: 350px) and (max-width: 400px) {

.cover-wrap {
height: 160px;
}
}

@media (min-width: 400px) and (max-width: 440px) {

.cover-wrap {
height: 180px;
}
}

@media (min-width: 440px) and (max-width: 500px) {

.cover-wrap {
height: 200px;
}
}

@media (min-width: 500px) and (max-width: 540px) {

    .cover-wrap {
    height: 210px;
}
}

@media (min-width: 540px) and (max-width: 577px) {

    .cover-wrap {
        height: 230px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {

.cover-wrap {
    height: 225px;
}
}



@media (min-width: 768px) and (max-width: 992px) {

.cover-wrap {
    height: 140px;
}
}

@media (min-width: 992px) and (max-width: 1200px) {

.cover-wrap {
    height: 200px;
}
}

.custom-font-color {
    color: var(--primary-purple);
}

.hr-size{
    border: 2px solid var(--primary-purple);
}
</style>