// Vue
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)

// Vue-Router
import router from './router'
app.use(router)

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.min.css'

// Vuetify
import '@mdi/font/css/materialdesignicons.min.css'
import 'material-icons/iconfont/material-icons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives
})
app.use(vuetify)

// Tailwind
// import "./assets/tailwind.css";

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init()

// Global Components
import ConfirmAlert from '@/components/alerts/ConfirmAlert'
import SnackAlert from '@/components/alerts/SnackAlert'
import LoadingScreen from '@/components/loading/LoadingScreen'
import LoadingScreenBool from '@/components/loading/LoadingScreenBool'
app.component('confirm-alert', ConfirmAlert);
app.component('snack-alert', SnackAlert);
app.component('loading-screen', LoadingScreen);
app.component('loading-screen-store', LoadingScreenBool);

// Init
app.mount('#app')