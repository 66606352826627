<template>
  <div class="bg-white">
    <div
      class="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8"
      data-aos="fade-up"
    >
      <SectionHeader
        title="Product Details"
        :description="description"
        :breadcrumbs="breadcrumbs"
      />
      <div class="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
        <!-- Image gallery -->
        <TabGroup as="div" class="flex flex-col-reverse" :selectedIndex="selectedTab">
          <!-- Image selector -->
          <div
            class="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none"
          >
            <div class="flex justify-between mb-4">
              <!-- Added flex container for buttons -->
              <button @click="prevPagination()"  v-if="pagination != 1" class="btn-left custom-i-color">
                <i class="bi bi-arrow-left-square-fill"></i> Back
              </button>
              <!-- Example left button -->
              <div v-else></div>
              <button @click="nextPagination()" v-if="pagination != picturePagination" class="btn-right custom-i-color">
                Next <i class="bi bi-arrow-right-square-fill"></i>
              </button>
              <!-- Example right button -->
            </div>
            <TabList class="grid grid-cols-4 gap-6" col>
              <Tab
                v-for="(media, index) in medias"
                :key="media.id"
                class="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                v-slot="{ selected }"
                @click="selectedTab = index"
              >
                <span class="sr-only">{{ media.name }}</span>
                <span class="absolute inset-0 overflow-hidden rounded-md">
                  <img
                    :src="formatImg(media.image)"
                    alt=""
                    class="h-full w-full object-cover object-center"
                  />
                </span>
                <span
                  :class="[
                    selected ? 'ring-purple-500' : 'ring-transparent',
                    'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                  ]"
                  aria-hidden="true"
                >
                </span>
                <!-- Play button icon -->
                <i
                  v-if="media.type === 'video'"
                  class="bi bi-play-fill text-6xl custom-i-color absolute"
                ></i>
              </Tab>
            </TabList>
            <br />
            <v-pagination
              v-model="pagination"
              :length="picturePagination"
            ></v-pagination>
          </div>

          <TabPanels class="aspect-h-1 aspect-w-1 w-full">
            <TabPanel v-for="media in medias" :key="media.id" >
              <img @click="onDisplay()"
                v-if="media.type === 'image'"
                :src="formatImg(media.image)"
                :alt="media.alt"
                class="object-cover object-center sm:rounded-lg max-h-96 mx-auto"
              />
              <video
                v-else
                controls
                controlsList="nodownload"
                autoplay
                muted
                class="object-cover object-center sm:rounded-lg max-h-96 mx-auto"
              >
                <source :src="formatVid(media.id)" type="video/mp4" />
                Your browser does not support video.
              </video>
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <!-- Product info -->
        <div class="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
          <h1 class="text-3xl font-bold tracking-tight text-gray-900">
            {{ productStore.item.name }}
          </h1>

          <div class="mt-6">
            <h3 class="sr-only">Description</h3>

            <div
              class="space-y-6 text-base rest-style text-gray-700"
              v-html="productStore.item.description"
            />
          </div>

             

          <section aria-labelledby="details-heading" class="mt-12">
            <h2 id="details-heading" class="sr-only">Additional details</h2>

            <div class="divide-y divide-gray-200 border-t">
              <Disclosure defaultOpen as="div" v-slot="{ open }">
                <h3>
                  <DisclosureButton
                    class="group relative flex w-full items-center justify-between py-6 text-left"
                  >
                    <span
                      :class="[
                        open ? 'text-purple-600' : 'text-gray-900',
                        'text-sm font-medium',
                      ]"
                      >Specifications</span
                    >
                    <span class="ml-6 flex items-center">
                      <PlusIcon
                        v-if="!open"
                        class="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <MinusIcon
                        v-else
                        class="block h-6 w-6 text-purple-400 group-hover:text-purple-500"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </h3>
                <DisclosurePanel
                  as="div"
                  class="prose prose-sm pb-6 flex flex-col gap-2"
                >
                  <span class="text-gray-700 text-sm"
                    ><strong>Product ID: </strong>{{ productId }}</span
                  >
                  <span
                    class="text-gray-700 text-sm"
                    v-for="spec in specifications"
                    :key="spec"
                    ><strong>{{ spec.attribute }}: </strong
                    >{{ spec.value }}</span
                  >
                </DisclosurePanel>
              </Disclosure>

              <Disclosure defaultOpen as="div" v-slot="{ open }">
                <h3>
                  <DisclosureButton
                    class="group relative flex w-full items-center justify-between py-6 text-left"
                  >
                    <span
                      :class="[
                        open ? 'text-purple-600' : 'text-gray-900',
                        'text-sm font-medium',
                      ]"
                      >Files</span
                    >
                    <span class="ml-6 flex items-center">
                      <PlusIcon
                        v-if="!open"
                        class="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <MinusIcon
                        v-else
                        class="block h-6 w-6 text-purple-400 group-hover:text-purple-500"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </h3>
                <DisclosurePanel
                  v-if="files.length > 0 || pdfs.length > 0"
                  as="div"
                  class="prose prose-sm pb-6 flex flex-col gap-2"
                >
                  <ul
                    role="list"
                    class="divide-y divide-gray-100 rounded-md border border-gray-200"
                  >
                    <li
                      v-for="file in files"
                      :key="file.id"
                      class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                    >
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          class="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{
                            file.fileName
                          }}</span>
                          <span class="flex-shrink-0 text-gray-400"
                            >{{ getFileSizeInKB(file.size) }} kB</span
                          >
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          class="font-medium text-purple-600 hover:text-purple-500"
                          @click="downloadFile(file)"
                          >Download</a
                        >
                      </div>
                    </li>

                    <li
                      v-for="file in pdfs"
                      :key="file.id"
                      class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                    >
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          class="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{
                            file.fileName
                          }}</span>
                          <span class="flex-shrink-0 text-gray-400"
                            >{{ getFileSizeInKB(file.size) }} kB</span
                          >
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          class="font-medium text-purple-600 hover:text-purple-500"
                          @click="downloadPdf(file)"
                          >Download</a
                        >
                      </div>
                    </li>
                  </ul>
                </DisclosurePanel>

                <DisclosurePanel
                  v-else
                  as="div"
                  class="prose prose-sm pb-6 flex flex-col gap-2"
                >
                  <span class="text-center m-2 text-gray-500"
                    >There are no files.</span
                  >
                </DisclosurePanel>
              </Disclosure>
              <button v-if="quotation" class="btn w-100 custom-button" @click="addQuotation">
                Request Quotation
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
:visible="visibleRef"
      :imgs="lightboxImages"
      :index="indexRef"
      @hide="onHide"
    ></vue-easy-lightbox>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/vue";
import config from "@/config/appConfig.json";
import { MinusIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { PaperClipIcon } from "@heroicons/vue/20/solid";

</script>

<script>
import { useShoppingCartStore } from "@/stores/shoppingCartStore";
import { useProductStore } from "@/stores/productStore";
import SectionHeader from "@/components/headers/SectionHeader";
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  name: "ProductDetailSection",
  data() {
    const productStore = useProductStore();
    const shoppingCartStore = useShoppingCartStore();

    return {
      visibleRef: false,
      selectedTab: 0,
      title: "Product Details",
      pagination: 1,
      productStore,
      shoppingCartStore,
    };
  },
  components: {
    VueEasyLightbox,
    SectionHeader,
  },
  props: {
    description: {
      type: String,
    },
    breadcrumbs: {
      type: Array,
    },
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
    specifications: {
      type: Array,
    },
    productId: {
      type: String,
    },
    files: {
      type: Array,
    },
    pdfs: {
      type: Array,
    },
    quotation: {
    type: Boolean,
  },
  },
  methods: {
    async addQuotation() {
      var product = {
        productId: this.productStore.item.id,
      };
      await this.shoppingCartStore.addItem(product);
    },
    onHide() {
      this.visibleRef = false;
    },
    onDisplay() {
      this.visibleRef = true;
    },
    setTab() {
      this.selectedTab = 0;
    },
    nextPagination() {
      if (this.pagination != this.picturePagination) {
        this.pagination++;
      }
    },
    prevPagination() {
      if (this.pagination > 1) {
        this.pagination--;
      }
    },
    formatImg(img) {
      return `data:image/png;base64,${img}`;
    },
    formatVid(vid) {
      return `${config.apiUrl}/api/video/${vid}`;
    },
    getFileSizeInKB(fileSizeInBytes) {
      const fileSizeInKB = (fileSizeInBytes / 1000).toFixed(2);
      return fileSizeInKB;
    },
    async downloadFile(file) {
      await this.productStore.downloadFile(this.urlId, file);
    },
    async downloadPdf(file) {
      await this.productStore.downloadPdf(this.urlId, file);
    },
    openLightbox(media) {
      this.lightboxIndex = this.lightboxImages.findIndex(m => m.src === media.src);
      this.lightboxOpen = true;
    },
    closeLightbox() {
      this.lightboxOpen = false;
    },
  },
  watch: {
    pagination() {
      this.setTab();
    }
  },
  computed: {
    lightboxImages() {
      let mediaList = [];
      this.images.forEach((img) => {
        img.type = "image";
        mediaList.push(img);
      });
      this.videos.forEach((vid) => {
        vid.type = "video";
        mediaList.push(vid);
      });
      mediaList.sort((a, b) => a.order - b.order);
      return mediaList
        .filter(media => media.type === 'image')
        .map(media => ({
          type: 'image',
          src: this.formatImg(media.image),
          alt: media.alt,
        }))
    },
    picturePagination() {
      const totalItems = this.images.length + this.videos.length;
    const itemsPerPage = 4;
    return Math.ceil(totalItems / itemsPerPage);
    },
    medias() {
      let mediaList = [];
      this.images.forEach((img) => {
        img.type = "image";
        mediaList.push(img);
      });
      this.videos.forEach((vid) => {
        vid.type = "video";
        mediaList.push(vid);
      });
      mediaList.sort((a, b) => a.order - b.order);
      return mediaList.slice((this.pagination - 1) * 4, this.pagination * 4);
    },
    urlId() {
      return this.$route.params.id;
    },
    
  },
};
</script>

<style scoped>
@import "@/../../../assets/tailwind.css";

.custom-image-div {
  height: 50vh;
}

.custom-i-color {
  color: #7030a0;
}

.custom-button {
  color: white;
  background-color: #7030a0;
}


.custom-button:hover {
  color: #7030a0;
  background-color: white;
  border: solid 1px #7030a0;
}


@media (max-width: 350px) {

.cover-wrap {
    height: 130px;
}
}

@media (min-width: 350px) and (max-width: 400px) {

.cover-wrap {
height: 160px;
}
}

@media (min-width: 400px) and (max-width: 440px) {

.cover-wrap {
height: 180px;
}
}

@media (min-width: 440px) and (max-width: 500px) {

.cover-wrap {
height: 200px;
}
}

@media (min-width: 500px) and (max-width: 540px) {

    .cover-wrap {
    height: 210px;
}
}

@media (min-width: 540px) and (max-width: 577px) {

    .cover-wrap {
        height: 230px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {

.cover-wrap {
    height: 225px;
}
}



@media (min-width: 768px) and (max-width: 992px) {

.cover-wrap {
    height: 140px;
}
}

@media (min-width: 992px) and (max-width: 1200px) {

.cover-wrap {
    height: 200px;
}
}
</style>
