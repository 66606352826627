<template>
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <img src="../../assets/img/logo.webp" alt="Caminos Logo" />
        <v-card rounded="xl" elevation="15" class="mx-auto" style="max-width: 600px;">
            <v-window disabled v-model="step">
                <v-window-item :value="1">
                    <LoginPanel @continue="next" v-on:updatePanel="updateLoginPanel" />
                </v-window-item>

                <v-window-item :value="2">
                    <TwoFactorPanel @continue="login" v-on:updatePanel="updateTwoFactorPanel" />
                </v-window-item>
            </v-window>
        </v-card>
    </section>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useShoppingCartStore } from "@/stores/shoppingCartStore";
import LoginPanel from './panels/LoginPanel.vue';
import TwoFactorPanel from './panels/TwoFactorPanel.vue';
import router from '@/router';

export default {
    name: "LoginView",
    components: {
        LoginPanel,
        TwoFactorPanel
    },
    data() {
        const userStore = useUserStore();
        const shoppingCartStore = useShoppingCartStore();

        return {
            shoppingCartStore,
            userStore,
            step: 1,
            email: '',
            password: '',
            code: ''
        }
    },
    methods: {
        next() {
            this.step++;
        },
        previous() {
            this.step--;
        },
        async login() {
            this.$root.toggleLoading();

            const credentials = {
                email: this.email,
                password: this.password,
                code: this.code
            };

            const response = await this.userStore.login(credentials);

            if (response) {
                this.$root.handleAlert('Login successful!');
                await this.shoppingCartStore.fetchCartItemCount();
                router.push('/');
            } else {
                this.$root.handleAlert('Verification failed!', 3000, 'red');
            }

            this.$root.toggleLoading();
        },
        updateLoginPanel({ email, password }) {
            this.email = email;
            this.password = password;
        },
        updateTwoFactorPanel({ code }) {
            this.code = code;
        }
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    width: 92.5%;
}

img {
    max-height: 100px;
    margin-bottom: 50px;
}
</style>