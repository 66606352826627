<template>
    <v-card-text>
        <img src="../../../assets/img/tutorial-enter-code.png" alt="Tutorial Image">

        <v-list lines="two">
            <v-list-item>
                <h5>Verify Yourself</h5>
                <span>
                    To complete the login process, use the code that is shown in the Google Authenticator application.
                    Entering the correct code, that is linked to your account, should result in a successful login. You are
                    now ready to use the application.
                </span>
            </v-list-item>
        </v-list>
    </v-card-text>
</template>

<script>
export default {
    name: "TutorialPanel5"
}
</script>

<style lang="scss" scoped src="../styles.scss"/>