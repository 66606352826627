<template>
    <main id="category" class="flex flex-col min-h-screen">
        <NavigationBar />
        <CategorySection class="flex-grow mt-5"  :description="`categories`" :categories="categories" :pages="pages"
            v-on:updatePanel="updatePanel" />
        <FooterSection class="footer" />
    </main>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import CategorySection from '@/components/categories/CategorySection';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useCategoryStore } from '@/stores/categoryStore';

export default {
    name: 'CategoryDetailPage',
    components: {
        NavigationBar,
        CategorySection,
        FooterSection
    },
    data() {
        const categoryStore = useCategoryStore();

        return {
            categoryStore,
            categories: [],
            page: 1,
            pages: 1
        }
    },
    watch: {
        '$route.params.id': {
            handler: 'fetch',
            immediate: true
        }
    },
    methods: {
        updatePanel({ page }) {
            this.page = page;
            this.fetch();
        },
        async fetch() {
            await this.categoryStore.fetchMain(this.page);
            this.categories = this.categoryStore.catalog;
            this.pages = this.categoryStore.pages;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>