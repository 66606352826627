<template>
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <img id="companyLogo" src="../../assets/img/logo.webp" alt="Caminos Logo" />
        <v-card rounded="xl" elevation="15" class="mx-auto" style="max-width: 700px;">
            <v-card-text>
                <v-card-title class="text-center">Google Authenticator</v-card-title>
                <v-card-text class="text-center">Download the app by visiting the store that is supported by your
                    device.</v-card-text>

                <v-col>
                    <div class="store-badge-container mt-3">
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                            <img src="../../assets/img/apple-store-badge.svg" alt="App Store Logo">
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US"
                            target="_blank">
                            <img src="../../assets/img/google-play-badge.png" alt="Google Play Logo">
                        </a>
                    </div>

                    <div class="app-logo-container">
                        <img src="../../assets/img/google-authenticator-logo.png" alt="Google Authenticator Logo">
                    </div>

                    <div class="button-container-custom">
                        <span>Don't know how this app works?</span>
                        <a href="/tutorial/google-authenticator" target="_blank">Tutorial</a>
                    </div>
                </v-col>
            </v-card-text>
        </v-card>
    </section>
</template>

<script>
export default {
    name: "AuthenticatorDownload"
}
</script>

<style lang="scss" scoped>
.v-card {
    width: 85%;
}

#companyLogo {
    max-height: 100px;
    margin-bottom: 50px;
}

.store-badge-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    justify-content: center;

    img {
        cursor: pointer;
    }

    @media (min-width: 700px) {
        img {
            width: auto;
            height: 55px;
        }
    }

    @media (max-width: 700px) {
        img {
            width: 100%;
            max-height: 40px;
        }
    }
}

.app-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 25px;

    @media (max-width: 700px) {
        img {
            width: 100%;
            max-width: 175px;
        }
    }
}

.button-container-custom {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    a {
        color: var(--primary-purple) !important;
        font-weight: bold;
        cursor: pointer;
    }

    a:hover {
        color: var(--faded-purple) !important;
    }
}
</style>