<template>
    <v-overlay :model-value="overlay" class="align-center justify-center" persistent>
        <div class="loading-container">
            <v-progress-circular color="black" :width="7" indeterminate size="75">
                <template v-if="counter" v-slot:default> {{ displayValue }} % </template>
            </v-progress-circular>
            <v-chip color="black" v-if="actions">
                {{ action }}
            </v-chip>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: "LoadingScreen",
    data() {
        return {
            overlay: false,
            value: 0,
            action: ''
        }
    },
    props: {
        counter: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggle() {
            this.overlay = !this.overlay;
            if (!this.overlay) {
                this.reset();
            }
        },
        addValue(num) {
            if (this.value + num > 100) {
                this.value = 100;
            } else {
                this.value += num;
            }
        },
        setAction(action) {
            this.action = action;
        },
        reset() {
            this.value = 0;
            this.action = '';
        }
    },
    computed: {
        displayValue() {
            return Math.ceil(this.value);
        }
    }
}
</script>

<style scoped>
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

span {
    color: rgb(0, 165, 255);
    font-weight: bold;
}
</style>