<template>
    <v-card-text>
        <v-form @submit.prevent="updatePanel">
            <v-card-title>Create Your Account</v-card-title>
            <v-card-text>Use the e-mail on which you received an invitation.</v-card-text>

            <v-col>
                <div class="input-field">
                    <v-text-field ref="mailField" label="E-mail" v-model="email" :rules="emailRules" type="email"
                        variant="solo" rounded="xl" density="compact"></v-text-field>
                </div>
                <hr>
                <div class="button-container-custom">
                    <v-btn :loading="buttonLoading" :disabled="!email" id="actionBtn" rounded="xl" elevation="5" type="submit">Next</v-btn>
                    <span>Already have an account? <a @click="navigate('/')">Log In</a></span>
                </div>
            </v-col>
        </v-form>
    </v-card-text>
</template>

<script>
import router from '@/router';
import { useUserStore } from '@/stores/userStore';
import { useRegisterSessionStore } from '../registerSessionStore';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    name: "CredentialsPanel",
    emits: ['updatePanel', 'continue'],
    data() {
        const userStore = useUserStore();
        const sessionStore = useRegisterSessionStore();

        return {
            userStore,
            sessionStore,
            v$: useVuelidate(),
            email: '',
            emailRules: [
                value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!value) return 'E-mail is required!';
                    else if (pattern.test(value)) return true
                    return 'Invalid e-mail!'
                }
            ],
            buttonLoading: false
        }
    },
    validations() {
        return {
            email: { required }
        }
    },
    async mounted() {
        await this.sessionStore.getData();
        await this.setPanel();
    },
    methods: {
        async setPanel() {
            this.email = this.sessionStore.email;
        },
        async updatePanel() {
            this.buttonLoading = true;
            this.$root.toggleLoading();

            if (this.email) this.email = this.email.trim();

            this.v$.$validate();
            const validMail = await this.$refs.mailField.validate() == false;
            const validCredentials = await this.userStore.validateRegistration({ email: this.email, secret: this.secret });

            if (this.v$.$error) {
                this.$root.handleAlert('Fill in all required fields!', 3000, 'red');
            } else if (!validMail) {
                this.$root.handleAlert('Use a valid e-mail!', 3000, 'red');
            } else if (!validCredentials) {
                this.$root.handleAlert('The following e-mail is not authorized!', 3000, 'red');
            } else {
                this.$emit('updatePanel', {
                    email: this.email,
                });

                this.$emit("continue");
            }

            this.$root.toggleLoading();
            this.buttonLoading = false;
        },
        navigate(link) {
            router.push(link);
        }
    },
    computed: {
        secret() {
            return this.$route.params.secret;
        }
    }
}
</script>

<style lang="scss" scoped src="../styles.scss"/>