<template>
    <main id="home" class="home">
        <NavigationBar />
        <HeroSection :home="true" />
        <CategorySection v-if="areLiked" :description="`products`" :products="products" :pages="pages"
            v-on:updatePanel="updatePanel" :home="isHome" />
            <br>
        <CategorySection :description="`categories`" :categories="categories" :pages="pages"
            v-on:updatePanel="updatePanel" :home="isHome" />
            <br>
            <br>   
        <FooterSection />
    </main>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import HeroSection from '@/components/hero/HeroSection';
import CategorySection from '@/components/categories/CategorySection';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useCategoryStore } from '@/stores/categoryStore';
import { useProductStore } from "@/stores/productStore";

export default {
    name: 'HomePage',
    components: {
        NavigationBar,
        HeroSection,
        CategorySection,
        FooterSection
    },
    data() {
        const productStore = useProductStore();
        const categoryStore = useCategoryStore();

        return {
            areLiked: false,
            productStore,
            isHome: true,
            categoryStore,
            categories: [],
            page: 1,
            pages: 1,
            products: []
        }
    },
    watch: {
        '$route.params.id': {
            handler: 'fetch',
            immediate: true
        }
    },
    methods: {
        updatePanel({ page }) {
            this.page = page;
            this.fetch();
        },
        async fetch() {
            await this.categoryStore.fetchMain(this.page);
            
            this.categories = this.categoryStore.catalog;
            this.pages = this.categoryStore.pages;
            let response = await this.productStore.fetchHomePage();
            this.areLiked = response.valid;
            this.products = this.productStore.homepageItems;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>