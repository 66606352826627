<template>
    <main id="search" class="flex flex-col min-h-screen" >
        <NavigationBar />
        <HeroSection :name="`Search`" :search="true" v-on:updatePanel="updateSearchPanel" />
        <br>
        <CategorySection :search="true" :title="`Products`" :description="``" :products="products" :pages="productPages" v-on:updatePanel="updateProductPanel" />
        <FooterSection />
    </main>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import HeroSection from '@/components/hero/HeroSection';
import CategorySection from '@/components/categories/CategorySection';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useCategoryStore } from '@/stores/categoryStore';
import { useProductStore } from '@/stores/productStore';

export default {
    name: "SearchPage",
    components: {
        NavigationBar,
        HeroSection,
        CategorySection,
        FooterSection
    },
    
    data() {
        const categoryStore = useCategoryStore();
        const productStore = useProductStore();

        return {
            categoryStore,
            productStore,
            query: '',
            categoryPage: 1,
            categoryPages: 1,
            productPage: 1,
            productPages: 1,
            categories: [],
            products: []
        }
    },  
    methods: {
        updateSearchPanel({ query }) {
            this.query = query;
            this.fetch();
        },
        updateCategoryPanel({ categoryPages }) {
            this.categoryPages = categoryPages;
            this.fetch();
        },
        updateProductPanel({ productPages }) {
            this.productPages = productPages;
            this.fetch();
        },
        async fetch() {
            await this.productStore.filter(this.query, this.productPage);
            this.products = this.productStore.catalog;
            this.productPages = this.productStore.pages;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>