<template>
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <img id="companyLogo" src="../../assets/img/logo.webp" alt="Caminos Logo" />
        <v-card rounded="xl" elevation="15" class="mx-auto" style="max-width: 1000px;">
            <v-card-text>
                <v-card-title class="text-center">Google Authenticator</v-card-title>
                <v-card-text class="text-center">Step by step how to use the app.</v-card-text>

                <v-col>
                    <v-stepper v-model="step" :items="items" rounded="xl" elevation="5">
                        <template v-slot:item.1>
                            <TutorialPanel1 />
                        </template>

                        <template v-slot:item.2>
                            <TutorialPanel2 />
                        </template>

                        <template v-slot:item.3>
                            <TutorialPanel3 />
                        </template>

                        <template v-slot:item.4>
                            <TutorialPanel4 />
                        </template>

                        <template v-slot:item.5>
                            <TutorialPanel5 />
                        </template>
                    </v-stepper>
                </v-col>
            </v-card-text>
        </v-card>
    </section>
</template>

<script>
import TutorialPanel1 from './panels/TutorialPanel1.vue';
import TutorialPanel2 from './panels/TutorialPanel2.vue';
import TutorialPanel3 from './panels/TutorialPanel3.vue';
import TutorialPanel4 from './panels/TutorialPanel4.vue';
import TutorialPanel5 from './panels/TutorialPanel5.vue';

export default {
    name: "AuthenticatorDownload",
    components: {
        TutorialPanel1,
        TutorialPanel2,
        TutorialPanel3,
        TutorialPanel4,
        TutorialPanel5
    },
    data() {
        return {
            step: 1,
            items: [
                'Download the app',
                'Add a code',
                'Scan a QR code',
                'Log in',
                'Enter code'
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    width: 85%;
}

#companyLogo {
    max-height: 75px;
    margin-bottom: 30px;
}
</style>