<template>
    <v-card-text>
        <img src="../../../assets/img/tutorial-login.png" alt="Tutorial Image">

        <v-list lines="two">
            <v-list-item>
                <h5>Log In</h5>
                <span>If you successfully completed previous steps, you can click on the "I'm ready" button on the register
                    page without worry. This will redirect you to the Login page, where you simply need to log in with the
                    e-mail and password you registered with.</span>
            </v-list-item>
        </v-list>
    </v-card-text>
</template>

<script>
export default {
    name: "TutorialPanel4"
}
</script>

<style lang="scss" scoped src="../styles.scss"/>