import config from '@/config/appConfig.json';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useLoadingStore } from './loadingStore';

const endpoint = `${config.apiUrl}/api/ShoppingCart`;

export const useShoppingCartStore = defineStore('shoppingCartStore', {
    state: () => ({
        list: [],
        item: {},
        itemCount: ""
    }),
    actions: {
        alert(text, error, background) {
            if (error && error.response && error.response.data) {
                text = error.response.data.message;
            }
            return { text: text, timeout: 3000, background: background };
        },
        async fetch(){
            let text = '';
            try {
                const response = await axios.get(endpoint + `/list`, { withCredentials: true });
                this.list = response.data;
            } catch (error) {
                text = 'Fetching shopping cart failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchCartItemCount(){
            let text = '';
            try {
                const response = await axios.get(endpoint + `/cartitemscount`, { withCredentials: true });
                this.itemCount = response.data;
            } catch (error) {
                text = 'Fetching shopping cart item count failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async sendQuotation(quotation) {
            let text = '';
            const loadingStore = useLoadingStore();
            loadingStore.start('');
            try {
                const response = await axios.post(endpoint + `/send/quotation`, quotation, { withCredentials: true });
                loadingStore.stop();
                return response.data;

            } catch (error) {
                loadingStore.stop();
                text = 'Sending quotation failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async addItem(item) {
            let text = '';
            const loadingStore = useLoadingStore();
            loadingStore.start('');
            try {
                const response = await axios.post(endpoint + `/add`, item, { withCredentials: true });
                await this.fetchCartItemCount();
                loadingStore.stop();
                return response.data;

            } catch (error) {
                loadingStore.stop();
                text = 'Adding item failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async update(product) {
            let text = '';
            const loadingStore = useLoadingStore();
            loadingStore.start('');
            try {
                const response = await axios.put(endpoint + `/update`, product, { withCredentials: true });
                loadingStore.stop();
                return response.data;

            } catch (error) {
                loadingStore.stop();
                text = 'Sending update failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async remove(id) {
            let text = '';
            try {
                await axios.delete(endpoint + `/remove/${id}`, { withCredentials: true });
                await this.fetchCartItemCount();
            } catch (error) {
                text = 'Removing shopping cart item failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        
    }
});