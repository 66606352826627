<template>
    <v-card-text>
        <v-form @submit.prevent="updatePanel">
            <v-card-title>Log In To Your Account</v-card-title>
            <v-card-text>Use your Caminos account.</v-card-text>

            <v-col>
                <div class="input-field">
                    <v-text-field label="E-mail" v-model="email" :rules="emailRules" type="email" variant="solo"
                        rounded="xl" density="compact"></v-text-field>
                    <v-text-field label="Password" v-model="password" :rules="passwordRules" type="password" variant="solo"
                        rounded="xl" density="compact"></v-text-field>
                </div>
                <hr>
                <v-btn :disabled="!email || !password" id="actionBtn" rounded="xl" elevation="5" class="w-100"
                    type="submit">Log In</v-btn>
            </v-col>
        </v-form>
    </v-card-text>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    name: "LoginCard1",
    emits: ['updatePanel', 'continue'],
    data() {
        const userStore = useUserStore();

        return {
            userStore,
            v$: useVuelidate(),
            email: '',
            emailRules: [
                value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!value) return 'E-mail is required!';
                    else if (pattern.test(value)) return true
                    return 'Invalid e-mail!'
                }
            ],
            password: '',
            passwordRules: [
                value => {
                    if (value) return true
                    return 'Password is required!'
                }
            ]
        }
    },
    validations() {
        return {
            email: { required },
            password: { required }
        }
    },
    methods: {
        async updatePanel() {
            this.$root.toggleLoading();

            if (this.email) this.email = this.email.trim();
            if (this.password) this.password = this.password.trim();

            this.v$.$validate();
            const validCredentials = await this.userStore.validateLogin({ email: this.email, password: this.password });

            if (this.v$.$error) {
                this.$root.handleAlert('Fill in all required fields!', 3000, 'red');
            } else if (!validCredentials) {
                this.$root.handleAlert('E-mail and/or password are incorrect!', 3000, 'red');
            } else {
                this.$emit('updatePanel', {
                    email: this.email,
                    password: this.password
                });

                this.$emit("continue");
            }

            this.$root.toggleLoading();
        }
    }
}
</script>

<style lang="scss" scoped src="../styles.scss"/>