import config from '@/config/appConfig.json';
import axios from 'axios';
import { defineStore } from 'pinia';

const endpoint = `${config.apiUrl}/api/category`;

export const useCategoryStore = defineStore('categoryStore', {
    state: () => ({
        catalog: [],
        item: {},
        navList: [],
        pages: 0
    }),
    actions: {
        alert(text, error, background) {
            if (error && error.response && error.response.data) {
                text = error.response.data.message;
            }
            return { text: text, timeout: 3000, background: background };
        },
        async fetchHome() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/homepage`, { withCredentials: true });
                this.catalog = response.data;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchNavList() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/list`, { withCredentials: true });
                this.navList = response.data;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchMain(page) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/main/page/${page}`, { withCredentials: true });
                this.catalog = response.data.categories;
                this.pages = response.data.pages;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchList() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/list`, { withCredentials: true });
                this.catalog = response.data;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchById(id, page) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${id}/page/${page}`, { withCredentials: true });
                this.item = response.data;
                this.pages = response.data.pages;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async filter(filter, page) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/filter/${filter}/page/${page}`, { withCredentials: true });
                this.catalog = response.data.categories;
                this.pages = response.data.pages;
            } catch (error) {
                text = 'Fetching categories failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        }
    }
});