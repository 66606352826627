<template>
  <div class="row-container">
    <span v-if="child" class="material-icons hover" @click="goBack">
      arrow_back_ios
    </span>
    <div class="section-title">
      <h2>{{ title }}</h2>
      <nav>
        <ol class="breadcrumb">
          <li v-if="description || title" class="breadcrumb-item">
            <router-link class="link" to="/">Home</router-link>
          </li>
          <li
            v-for="bread in breadcrumbs"
            :key="bread.id"
            class="breadcrumb-item"
          >
            <router-link class="link" :to="'/categories/' + bread.id">{{bread.name}}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ this.description }}</li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      child: false,
    };
  },
  mounted() {
    this.child =
      this.$route.path !== "/" &&
      this.$route.path !== "/categories" &&
      this.$route.path !== "/search";
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  padding-bottom: 40px;
}

.hover {
  cursor: pointer;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #7030a0;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}

.breadcrumb-item {
  font-size: 20px;
  font-weight: 500;
  color: gray !important;
}

.link{
  color: #7030a0;
}
</style>
