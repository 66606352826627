<template>
  <div class="bg-white">
    <main
      class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Quotation
      </h1>

      <div
        class="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16"
      >
        <section aria-labelledby="cart-heading" class="lg:col-span-7">

          <ul
            role="list"
            class="divide-y divide-gray-200 border-b border-t border-gray-200"
          >
            <li
              v-for="(product) in shoppingCartStore.list"
              :key="product.id"
              class="flex py-6 sm:py-10"
            >
              <div class="flex-shrink-0">
                <img
                  :src="formatImage(product.coverImage)"
                  class="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                />
              </div>

              <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div
                  class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0"
                >
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm">
                        <a
                          class="font-medium text-gray-700 hover:text-gray-800"
                          >{{ product.productName }}</a
                        >
                        <p class="text-gray-500">
                          {{ product.productDescription }}
                        </p>
                      </h3>
                    </div>
                  </div>

                  <div class=" sm:mt-0 sm:pr-9">
                    <label class="mr-2 text-gray-500">Count: </label>
                    <input
                    @change="updateQuantity(product.id, product.quantity)"
                      type="number"
                      v-model="product.quantity"
                      class="rounded-md py-1.5 px-3 text-base font-medium leading-5 text-gray-700 focus:border-gray-600 focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm"
                      min="1"
                      max="100"
                    />

                    <div class="absolute right-0 top-0">
                      <button
                        type="button"
                        @click="remove(product.id)"
                        class="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span class="sr-only">Remove</span>
                        <XMarkIconMini class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </ul>
        </section>

        <!-- Order summary -->
        <section
          aria-labelledby="summary-heading"
          class="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
        >
          <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
            Quotation note
          </h2>

          <textarea
      v-model="message"
      rows="5"
      class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
      placeholder="Enter your message here"
    ></textarea>

          <div class="mt-6">
            <button class="btn w-100 custom-button" @click="sendQuotation">
                Request Quotation
              </button>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script setup>
import {
  XMarkIcon as XMarkIconMini,
} from "@heroicons/vue/20/solid";
</script>

<script>
/*
import router from '@/router';
import { useUserStore } from '@/stores/userStore';
import { useRegisterSessionStore } from '../registerSessionStore';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';*/
import { useShoppingCartStore } from "@/stores/shoppingCartStore";
import _ from 'lodash';

export default {
  name: "ItemsPanel",
  emits: ["updatePanel"],
  data() {
    const shoppingCartStore = useShoppingCartStore();
    return {
      message: '',
      shoppingCartStore,
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    updateQuantity: _.throttle(async function(id , quantity) {
      let update={
        id: id,
        newCount: quantity
      }
      await this.shoppingCartStore.update(update);
          }, 1000), 
    async remove(id) {
      await this.shoppingCartStore.remove(id);
      await this.shoppingCartStore.fetch();
    },
    async sendQuotation() {
      let quotation = {
        note: this.message,
      };
      await this.shoppingCartStore.sendQuotation(quotation);
      await this.shoppingCartStore.fetch();
      this.message = '';
    },
    formatImage(img) {
      return `data:image/png;base64,${img}`;
    },
  },
};
</script>

<style scoped>
@import "@/../../../../assets/tailwind.css";

.custom-button {
  color: white;
  background-color: #7030a0;
}


.custom-button:hover {
  color: #7030a0;
  background-color: white;
  border: solid 1px #7030a0;
}
</style>
