<template>
    <div class="text-center">
        <v-snackbar rounded="xl" :color="background" v-model="snackbar" :timeout="timeout">
            <span>{{ text }}</span>

            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="toggle">
                    <span class="material-icons">close</span>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "SnackAlert",
    props: {
        timeout: {
            type: Number,
            default: 3000
        },
        text: {
            type: String,
            required: true
        },
        background: {
            type: String,
            default: 'green'
        }
    },
    data() {
        return {
            snackbar: false
        }
    },
    methods: {
        toggle() {
            this.snackbar = !this.snackbar;
        }
    }
}
</script>

<style scoped>
span {
    color: white;
}
</style>