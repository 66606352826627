<template>
    <v-overlay :model-value="loading" class="align-center justify-center" persistent>
        <div class="loading-container">
            <v-progress-circular color="black" :width="7" indeterminate size="75">
                <template v-if="counter" v-slot:default> {{ displayValue }} % </template>
            </v-progress-circular>
            <v-chip color="black" v-if="actions">
                {{ actions }}
            </v-chip>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: "LoadingScreen",
    props: {
        counter: {
            type: Number
        },
        actions: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        displayValue() {
            return Math.ceil(this.counter);
        }
    }
}
</script>

<style scoped>
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
</style>