<template>
  <section id="hero" class="d-flex align-items-center">
      <div class="container" data-aos="fade-up">
          
          <!-- Home Hero -->
          <div v-if="home" class="row justify-content-center">
              <div class="col-md-6 d-flex flex-column justify-content-center">
                  <h1>Caminos</h1>
                  <h2>Welcome!</h2>
              </div>
              <div class="col-md-6 hero-img" data-aos="zoom-in" data-aos-delay="150">
                  <img src="../../assets/img/logoandtextwhite.png" class="theLogo" alt="">
              </div>
          </div>

          <div v-else-if="search" class="search-container">
              <h1 class="text-center">{{ name }}</h1>
              
              <v-text-field autofocus @keydown.enter.prevent="updatePanel" v-model="query" prepend-inner-icon="mdi-magnify" label="Search" rounded="xl" density="comfortable"
                  variant="solo" class="search-field">
                  <template v-slot:append-inner>
                      <v-btn id="actionBtn" rounded="xl" @click="updatePanel">
                          <span class="material-icons">search</span>
                      </v-btn>
                  </template>
              </v-text-field>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  emits: ['updatePanel'],
  props: {
      home: {
          type: Boolean,
          default: false
      },
      search: {
          type: Boolean,
          default: false
      },
      name: {
          type: String
      }
  },
  data() {
      return {
          query: ''
      }
  },
  methods: {
      updatePanel() {
          this.$emit('updatePanel', {
              query: this.query
          });
      }
  }
}
</script>

<style lang="scss" scoped>
#hero {
  width: 100%;
  height: 200px;
  background: #7030A0;
  background-size: cover;
  margin-bottom: 20px;
}

#hero h1 {
  margin: 0;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.8);
  margin: 10px 0 0 0;
  font-size: 20px;
}

#hero {
  padding-top: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;;
  align-content: center;
  .hero-img {
      display: flex;
      align-items: center;
      justify-content: right;
  }

  img {
      max-height: 200px
  }
}

#heroSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  .hero-img {
      display: flex;
      align-items: center;
      justify-content: right;
  }

  img {
      max-height: 200px
  }
}

.search-container {
  display: flex;
  justify-content: center;

  .search-field {
      position: absolute;
      max-width: 550px;
      width: 100%;
      margin-top: 100px;
      padding: 0px 25px;
  }
}


.theLogo {
  width: 65%;
  margin-bottom: 3%;
}

@media only screen and (max-width: 768px) {
  .theLogo {
      width: 80%;
  }
}

</style>