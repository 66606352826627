<template>
    <v-card-text>
        <v-form @submit.prevent="updatePanel">
            <v-card-title>Create Your Account</v-card-title>
            <v-card-text>Make sure to create a strong password. Your password must be at least 20 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.</v-card-text>

            <v-col>
                <div class="input-field">
                    <v-text-field @input="checkMatch" ref="passField" label="Password" v-model="password"
                        :rules="passwordRules" type="password" variant="solo" rounded="xl" density="compact"></v-text-field>
                    <v-text-field ref="conField" label="Confirm Password" v-model="confirmPassword"
                        :rules="confirmPasswordRules" type="password" variant="solo" rounded="xl"
                        density="compact"></v-text-field>
                </div>
                <hr>
                <v-row>
                    <v-col cols="6">
                        <v-btn id="cancelBtn" rounded="xl" elevation="5" @click="cancel">Back</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn :loading="buttonLoading" :disabled="!password || !confirmPassword" id="actionBtn" rounded="xl" elevation="5"
                            type="submit">Create</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-form>
    </v-card-text>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    name: "PasswordPanel",
    emits: ['updatePanel', 'continue', 'back'],
    data() {
        return {
            v$: useVuelidate(),
            password: '',
            passwordRules: [
                value => {
                    if (!value) {
                        return 'Password is required!';
                    } else if (value.length < 20) {
                        return 'Password should contain at least 20 characters!';
                    } else if (!/[a-z]/.test(value)) {
                        return 'Password should contain at least 1 lowercase character!';
                    } else if (!/[A-Z]/.test(value)) {
                        return 'Password should contain at least 1 uppercase character!';
                    } else if (!/\d/.test(value)) {
                        return 'Password should contain at least 1 number!';
                    } else if (!/[!@#$%^&*()_+[\]{}|\\:;"'<>,.?/~`]/.test(value)) {
                        return 'Password should contain at least 1 special character!';
                    }
                    return true;
                }
            ],
            confirmPassword: '',
            confirmPasswordRules: [
                value => {
                    if (!value) {
                        return 'Confirm password is required!';
                    } else if (value != this.password) {
                        return 'Passwords do not match!';
                    }
                    return true;
                }
            ],
            buttonLoading: false
        }
    },
    validations() {
        return {
            password: { required },
            confirmPassword: { required }
        }
    },
    methods: {
        async checkMatch() {
            await this.$refs.conField.validate();
        },
        async updatePanel() {
            this.buttonLoading = true;
            this.$root.toggleLoading();

            if (this.password) this.password = this.password.trim();
            if (this.confirmPassword) this.confirmPassword = this.confirmPassword.trim();

            this.v$.$validate();
            const result = await this.$refs.passField.validate() == false;

            if (this.v$.$error) {
                this.$root.handleAlert('Fill in all required fields!', 3000, 'red');
            } else if (this.password != this.confirmPassword) {
                this.$root.handleAlert('Passwords should match!', 3000, 'red');
            } else if (!result) {
                this.$root.handleAlert('Passwords are not valid!', 3000, 'red');
            } else {
                this.$emit('updatePanel', {
                    password: this.password,
                    confirmPassword: this.confirmPassword
                });

                this.$emit("continue");
            }

            this.$root.toggleLoading();
            this.buttonLoading = false;
        },
        cancel() {
            this.$emit("back");
            this.$refs.passField.reset();
            this.$refs.conField.reset();

            this.$emit('updatePanel', {
                password: '',
                confirmPassword: ''
            });
        }
    }
}
</script>

<style lang="scss" scoped src="../styles.scss"/>