<template>
    <main id="product" class="product flex flex-col min-h-screen">
        <NavigationBar />
        <ItemsPanel :items="shoppingCartItems" />
        <FooterSection />
    </main>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import ItemsPanel from '@/views/shoppingCart/panels/ItemsPanel.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useShoppingCartStore } from '@/stores/shoppingCartStore';

export default {
    name: 'HomePage',
    components: {
        NavigationBar,
        ItemsPanel,
        FooterSection
    },
    data() {
        const shoppingCartStore = useShoppingCartStore();

        return {
            shoppingCartStore,
            shoppingCartItems: []

        }
    },
    async mounted() {
       await this.fetch();
    },
    methods: {
        async fetch() {
            await this.shoppingCartStore.fetch();
            this.shoppingCartItems = this.shoppingCartStore.list;
        }
    }
}
</script>

<style scoped>
@import "@/../../../assets/tailwind.css";
</style>