<template>
    <v-card-text>
        <img src="../../../assets/img/tutorial-download-app.png" alt="Tutorial Image">

        <v-list lines="two">
            <v-list-item>
                <h5>Visit App Store</h5>
                <span>Visit the App Store compatible with your device. For most cases this will be the App Store (on Apple
                    products), or the Play Store (on Android products).</span>
            </v-list-item>

            <v-list-item>
                <h5>Search The App</h5>
                <span>Search for "Google Authenticator" in the store.</span>
            </v-list-item>

            <v-list-item>
                <h5>Download The App</h5>
                <span>Click on the App developed by Google (LLC), and download this application.</span>
            </v-list-item>

            <v-list-item>
                <div class="alternative-item">
                    <div>
                        <h5>Alternative</h5>
                        <span>Alternatively you can also scan the following QR-code to help you find the correct
                            application.</span>
                    </div>
                    <div class="app-qr-container">
                        <img src="../../../assets/img/qrdownload.jpg" alt="QR-code for app download">
                    </div>
                </div>
            </v-list-item>
        </v-list>
    </v-card-text>
</template>

<script>
export default {
    name: "TutorialPanel1"
}
</script>

<style lang="scss" scoped src="../styles.scss"/>