<template>
    <v-card-text>
        <v-form @submit.prevent="updatePanel">
            <v-card-title>Verify Yourself</v-card-title>
            <v-card-text>Enter the code from your authenticator.</v-card-text>

            <v-col>
                <v-otp-input v-model="code" placeholder="0"></v-otp-input>
                <hr>
                <div class="button-container-custom">
                    <v-btn :loading="buttonLoading" :disabled="code.length != 6" id="actionBtn" rounded="xl" elevation="5" class="w-100"
                        type="submit">Verify</v-btn>
                    <span>Forgot how this works? <a href="/tutorial/google-authenticator" target="_blank">Tutorial</a></span>
                </div>
            </v-col>
        </v-form>
    </v-card-text>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    name: "LoginCard3",
    emits: ['updatePanel', 'continue'],
    data() {
        const userStore = useUserStore();

        return {
            userStore,
            v$: useVuelidate(),
            code: '',
            buttonLoading: false
        }
    },
    validations() {
        return {
            code: { required }
        }
    },
    methods: {
        updatePanel() {
            this.buttonLoading = true;
            this.$root.toggleLoading();

            this.v$.$validate();
            if (this.v$.$error) {
                this.$root.handleAlert('Fill in all required fields!', 3000, 'red');
            } else {
                this.$emit('updatePanel', {
                    code: this.code
                });

                this.$emit("continue");
            }

            this.$root.toggleLoading();
            this.buttonLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped src="../styles.scss"/>