<template>
    <v-card-text>
        <img src="../../../assets/img/tutorial-add-code.png" alt="Tutorial Image">

        <v-list lines="two">
            <v-list-item>
                <h5>Open The App</h5>
                <span>When opening the App for the first time, you will see a short introduction on screen. Read this by
                    swiping trough every slide, or skip this by clicking on the "Get Started" button.</span>
            </v-list-item>

            <v-list-item>
                <h5>Sign In</h5>
                <span>You will be asked to sign in. This is useful to keep al your scanned codes safe, by saving them on an
                    account. However this is completely optional, and can be skipped by clicking on the "Use Authenticator
                    without an account"
                    button.</span>
            </v-list-item>

            <v-list-item>
                <h5>Add A Code</h5>
                <span>To add a code simply click on the "Add a code" button coloured in blue.</span>
            </v-list-item>

            <v-list-item>
                <h5>Scan A QR Code</h5>
                <span>To open the actual camera to scan a QR code, you will have to press the first option "Scan a QR code".
                    When clicking this option for the first time, you will likely be asked to give the app permission to use
                    the
                    camera. If so, accept this request.</span>
            </v-list-item>
        </v-list>
    </v-card-text>
</template>

<script>
export default {
    name: "TutorialPanel2"
}
</script>

<style lang="scss" scoped src="../styles.scss"/>