import config from '@/config/appConfig.json';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useLoadingStore } from './loadingStore';

const endpoint = `${config.apiUrl}/api/product`;

export const useProductStore = defineStore('productStore', {
    state: () => ({
        catalog: [],
        homepageItems: [],
        item: {},
        pdfs: []
    }),
    actions: {
        alert(text, error, background) {
            if (error && error.response && error.response.data) {
                text = error.response.data.message;
            }
            return { text: text, timeout: 3000, background: background };
        },
        async fetchHomePage() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/homepage`, { withCredentials: true });
                this.homepageItems = response.data;
                return { valid: true };
            } catch (error) {
                text = 'Fetching products failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchById(id, categoryId) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${id}`+(categoryId ? `?categoryid=${categoryId}` : ''), { withCredentials: true });
                this.item = response.data;
                this.pdfs = response.data.pdfs;
            } catch (error) {
                text = 'Fetching product failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchImageById(productId, imageId) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${productId}/image/${imageId}`, { withCredentials: true });
                return response.data;
            } catch (error) {
                text = 'Fetching image failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchVideoById(productId, videoId) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${productId}/video/${videoId}`, { withCredentials: true });
                return response.data;
            } catch (error) {
                text = 'Fetching video failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchPdfById(productId, pdfId) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${productId}/pdf/${pdfId}`, { withCredentials: true });
                return response.data;
            } catch (error) {
                text = 'Fetching pdf failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async downloadFile(productId, file) {
            const loadingStore = useLoadingStore();

            axios.get(`${endpoint}/${productId}/file/download/${file.id}`, {
                withCredentials: true,
                responseType: 'arraybuffer',
                onDownloadProgress: (progressEvent) => {
                    loadingStore.start('Downloading file');
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    loadingStore.counter = parseInt(percentCompleted);
                }
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${file.fileName}`;
                link.click();
                loadingStore.stop();
            }).catch(() => {
                loadingStore.stop();
            });
        },
        async downloadPdf(productId, file) {
            const loadingStore = useLoadingStore();

            axios.get(`${endpoint}/${productId}/pdf/download/${file.id}`, {
                withCredentials: true,
                responseType: 'arraybuffer',
                onDownloadProgress: (progressEvent) => {
                    loadingStore.start('Downloading pdf');
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    loadingStore.counter = parseInt(percentCompleted);
                }
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${file.fileName}`;
                link.click();
                loadingStore.stop();
            }).catch(() => {
                loadingStore.stop();
            });
        },
        async filter(filter, page) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/filter/${filter}/page/${page}`, { withCredentials: true });
                this.catalog = response.data.products;
                this.pages = response.data.pages;
            } catch (error) {
                text = 'Fetching products failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        }
    }
});