import { defineStore } from 'pinia';

export const useRegisterSessionStore = defineStore('registerSessionStore', {
    state: () => ({
        step: 1,
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        country: 0,
        qr: ''
    }),
    actions: {
        setStep(step) {
            this.step = step;
            sessionStorage.setItem('step', this.step);
        },
        setCredentials(email) {
            this.email = email;
            sessionStorage.setItem('email', this.email);
        },
        setUserInfo(username, firstName, lastName, country) {
            this.username = username;
            this.firstName = firstName;
            this.lastName = lastName;
            this.country = country;
            sessionStorage.setItem('username', this.username);
            sessionStorage.setItem('firstName', this.firstName);
            sessionStorage.setItem('lastName', this.lastName);
            sessionStorage.setItem('country', this.country);
        },
        setQr(qr) {
            this.qr = qr;
            sessionStorage.setItem('qr', this.qr);
        },
        getData() {
            this.step = sessionStorage.getItem('step') || 1;
            this.email = sessionStorage.getItem('email') || '';
            this.username = sessionStorage.getItem('username') || '';
            this.firstName = sessionStorage.getItem('firstName') || '';
            this.lastName = sessionStorage.getItem('lastName') || '';
            this.country = sessionStorage.getItem('country') || 0;
            this.qr = sessionStorage.getItem('qr') || '';
        },
        clearData() {
            this.step = 1;
            this.email = '';
            this.username = '';
            this.firstName = '';
            this.lastName = '';
            this.country = '';
            this.qr = '';
            sessionStorage.clear();
        }
    },
    persist: {
        storage: sessionStorage
    }
});