import config from '@/config/appConfig.json';
import axios from 'axios';
import { defineStore } from 'pinia';

const endpoint = `${config.apiUrl}/api/user`;

export const useUserStore = defineStore('userStore', {
    state: () => ({
        account: {},
        username: '',
        firstName: '',
        lastName: '',
        all: [],
        catalog: [],
        pages: 0,
        countries: []
    }),
    actions: {
        saveData(credentials) {
            this.username = credentials.username;
            this.firstName = credentials.firstName;
            this.lastName = credentials.lastName;
            sessionStorage.setItem('username', this.username);
            sessionStorage.setItem('firstName', this.firstName);
            sessionStorage.setItem('lastName', this.lastName);
        },
        getData() {
            this.username = sessionStorage.getItem('username');
            this.firstName = sessionStorage.getItem('firstName');
            this.lastName = sessionStorage.getItem('lastName');
        },
        alert(text, error, background) {
            if (error && error.response && error.response.data) {
                text = error.response.data.message;
            }
            return { text: text, timeout: 3000, background: background };
        },
        async authorize() {
            let text = '';

            try {
                await axios.get(endpoint + `/logged-in/portal-user`, { withCredentials: true });
                return { valid: true };
            } catch (error) {
                text = 'You are not authorized.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetch() {
            let text = '';

            try {
                const response = await axios.get(endpoint, { withCredentials: true });
                this.all = response.data;
            } catch (error) {
                text = 'Fetching users failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchById(id) {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/${id}`, { withCredentials: true });
                return { valid: true, user: response.data };
            } catch (error) {
                text = 'Fetching user by ID failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchByPage(page, roleId, filter) {
            let text = '';

            try {
                let url = endpoint + `/pagination/${page}` + (roleId ? `?roleid=${roleId}` : '') + (filter ? `?filter=${filter}` : '');
                const response = await axios.get(url, { withCredentials: true });
                this.pages = response.data.pageCount;
                this.catalog = response.data.users;
            } catch (error) {
                text = 'Fetching users by page failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async fetchCountries() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/countries`);
                this.countries = response.data;
            } catch (error) {
                text = 'Fetching countries failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async register(credentials) {
            let text = '';
            try {
                const response = await axios.post(endpoint + `/register`, credentials);
                return { valid: true, qr: response.data.twoFactorAuthQrCodeUri };
            } catch (error) {
                text = 'Registration failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async login(credentials) {
            try {
                const response = await axios.post(endpoint + `/login`, credentials, { withCredentials: true });
                this.saveData(response.data);
                return true;
            } catch (error) {
                return false;
            }
        },
        async logout() {
            let text = '';

            try {
                await axios.get(endpoint + `/logout`, { withCredentials: true });
                sessionStorage.clear();
                text = 'Logged out.';
                return { valid: true, alert: this.alert(text, null, 'green') };
            } catch (error) {
                text = 'Logout failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async validateRegistration(credentials) {
            try {
                await axios.post(endpoint + `/register/validate/invitation`, credentials);
                return true;
            } catch (error) {
                return false;
            }
        },
        async validateLogin(credentials) {
            try {
                await axios.post(endpoint + `/login/validate`, credentials);
                return true;
            } catch (error) {
                return false;
            }
        },
        async reactivate(id) {
            let text = '';

            try {
                await axios.put(endpoint + `/${id}/reactivate`, null, { withCredentials: true });
                text = 'Reactivated account.';
                return { valid: true, alert: this.alert(text, null, 'green') };
            } catch (error) {
                text = 'Reactivating account failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async addProduct(credentials) {
            let text = '';

            try {
                await axios.post(endpoint + `/product/add`, credentials, { withCredentials: true });
            } catch (error) {
                text = 'Adding product to user failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async addCategory(credentials) {
            let text = '';

            try {
                await axios.post(endpoint + `/category/add`, credentials, { withCredentials: true });
            } catch (error) {
                text = 'Adding category to user failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async removeProduct(userId, productId) {
            let text = '';

            try {
                await axios.delete(endpoint + `/${userId}/product/${productId}/remove`, { withCredentials: true });
            } catch (error) {
                text = 'Removing product from user failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async removeCategory(userId, categoryId) {
            let text = '';

            try {
                await axios.delete(endpoint + `/${userId}/category/${categoryId}/remove`, { withCredentials: true });
            } catch (error) {
                text = 'Removing category from user failed.';
                return { valid: false, alert: this.alert(text, error, 'red') };
            }
        },
        async delete(id) {
            let text = '';

            try {
                await axios.delete(endpoint + `/${id}`, { withCredentials: true });
                text = 'User deleted.';
                return { valid: true, alert: this.alert(text, null, 'green') };
            } catch (error) {
                text = 'Deleting user failed.';
                return this.alert(text, error, 'red');
            }
        },
        async fetchAccount() {
            let text = '';

            try {
                const response = await axios.get(endpoint + `/account`, { withCredentials: true });
                this.account = response.data;
                return { valid: true };
            } catch (error) {
                text = 'Fetching account information failed.';
                return this.alert(text, error, 'red');
            }
        }
    },
    getters: {
        getCountryName: (state) => (id) => {
            const country = state.countries.find(country => country.id === id);
            if (country) return country.name;
            return 'NOT FOUND';
        }
    },
    persist: {
        storage: sessionStorage
    }
});