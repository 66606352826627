<template>
    <v-card-text>
        <img src="../../../assets/img/tutorial-use-code.png" alt="Tutorial Image">

        <v-list lines="two">
            <v-list-item>
                <h5>Register In The Portal</h5>
                <div class="list-custom">
                    <span>
                        You are now ready to scan QR-codes. To obtain the QR-code necessary for logging in you will need to
                        complete the registration steps.
                    </span>

                    <span>
                        <strong>1. Enter E-mail:</strong>
                        Enter the mail-address that received an invitation.
                    </span>

                    <span>
                        <strong>2. Enter User Details:</strong>
                        Tell more about yourself.
                    </span>

                    <span>
                        <strong>3. Enter Passwords:</strong>
                        Create a strong password.
                    </span>

                    <span>
                        <strong>4. Download Google Authenticator:</strong>
                        If you are reading this tutorial this step will not be necessary. The QR-code on this
                        slide is NOT the QR-code that you need to scan with Google Authenticator.
                    </span>

                    <span>
                        <strong>5. Scan QR-code:</strong>
                        The final step of the registration is to scan the QR-code presented on screen with Google
                        Authenticator. This QR-code will
                        only be shown once, and is important to verify yourself in later cases of the application. DO NOT
                        LEAVE THE REGISTRATION PAGE WITHOUT SCANNING THIS QR CODE FIRST!
                    </span>
                </div>
            </v-list-item>

            <v-list-item>
                <h5>Check Code In App</h5>
                <span>If you successfully scanned the code on the screen, with Google Authenticator, you will see a page
                    similar to the image shown. This code will refresh after a set amount of time, so make sure to always
                    look in the application for the newest code.</span>
            </v-list-item>
        </v-list>
    </v-card-text>
</template>

<script>
export default {
    name: "TutorialPanel3"
}
</script>

<style lang="scss" scoped src="../styles.scss"/>