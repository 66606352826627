<template>
  <header class="header">
    <!-- Full Screen -->
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <a @click="navigate('/')" class="-m-1.5 p-1.5 cursor-pointer">
          <span class="sr-only">Caminos</span>
          <img
            class="h-12 w-auto"
            src="../../assets/img/logo_white.png"
            alt="Caminos Logo"
          />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 hover-effect"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6 hover-effect" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a
          v-for="item in navigation"
          :key="item.name"
          @click="navigate(item.link)"
          class="text-sm font-semibold leading-6 hover-effect"
          >{{ item.name }}</a
        >
        <Popover class="relative" v-slot="{ close }">
          <PopoverButton
            class="color-white focus:outline-none inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
          >
            <span class="color-white">Categories</span>
            <ChevronDownIcon class="h-5 w-5 color-white" aria-hidden="true" />
          </PopoverButton>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
          >
            <PopoverPanel
              class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4"
            >
           
              <div
                class="vw-75 shrink rounded-xl bg-white p-4 text-sm font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5"
              >
              <a @click="goToAllCategories" class="mb-3 custom-a">Go to all categories</a>
                <div class="row mt-2">
                  <ul
                    class="col-3 primary-color max-h-80 overflow-y-auto scrollbar"
                  >
                    <li
                      @click="handleItemClick(navitem.id, close)"
                      @mouseover="firstNavListChange(navitem.id)"
                      :class="{
                        activenavItem: navitem.id === idFirstSelectedId,
                        navItem: navitem.id !== idFirstSelectedId,
                      }"
                      v-for="navitem in categoryStore.navList"
                      :key="navitem.id"
                    >
                      {{ navitem.name }}
                    </li>
                  </ul>
                  <ul
                    class="col-3 primary-color max-h-80 overflow-y-auto scrollbar"
                  >
                    <li
                      @click="handleItemClick(navitem.id, close)"
                      @mouseover="secondNavListChange(navitem.id)"
                      :class="{
                        activenavItem: navitem.id === idSecondSelectedId,
                        navItem: navitem.id !== idSecondSelectedId,
                      }"
                      v-for="navitem in secondNavList"
                      :key="navitem.id"
                    >
                      {{ navitem.name }}
                    </li>
                  </ul>
                  <ul
                    class="col-3 primary-color max-h-80 overflow-y-auto scrollbar"
                  >
                    <li
                      @click="handleItemClick(navitem.id, close)"
                      @mouseover="thirdNavListChange(navitem.id)"
                      :class="{
                        activenavItem: navitem.id === idThirdSelectedId,
                        navItem: navitem.id !== idThirdSelectedId,
                      }"
                      class="navItem"
                      v-for="navitem in thirdNavList"
                      :key="navitem.id"
                    >
                      {{ navitem.name }}
                    </li>
                  </ul>
                  <ul
                    class="col-3 primary-color max-h-80 overflow-y-auto scrollbar"
                  >
                    <li
                      @click="handleItemClick(navitem.id, close)"
                      @mouseover="fourthNavListChange(navitem.id)"
                      class="navItem"
                      v-for="navitem in fourthNavList"
                      :class="{
                        activenavItem: navitem.id === idFourthSelectedId,
                        navItem: navitem.id !== idFourthSelectedId,
                      }"
                      :key="navitem.id"
                    >
                      {{ navitem.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <i
          class="bi bi bi-search material-icons mr-3 hover-effect "
          @click="search"
        ></i>
        <div class="position-relative mr-2" @click="quotation">
          <i class="bi bi-cart4 material-icons mr-2 hover-effect"></i>
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          >
            {{ shoppingCartStore.itemCount }}
            <span class="visually-hidden">items in cart</span>
          </span>
        </div>

        <i
          class="bi bi-box-arrow-left material-icons hover-effect"
          @click="logout"
        ></i>
      </div>
    </nav>

    <!-- Mobile -->
    <Dialog
      as="div"
      class="lg:hidden"
      @close="mobileMenuOpen = false"
      :open="mobileMenuOpen"
    >
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 mobile-header"
      >
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Caminos</span>
            <img
              class="h-12 w-auto"
              src="../../assets/img/logo_white.png"
              alt="Caminos Logo"
            />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a
                v-for="item in navigation"
                :key="item.name"
                @click="navigate(item.link)"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover-effect-black"
                >{{ item.name }}</a
              >
            </div>
            <div class="space-y-2 py-6">
              <a
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover-effect-black"
                >Logout</a
              >
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";

const navigation = [
  { name: "Home", link: "/" },
  //    { name: 'Contact', link: '/contact' }
];
</script>

<script>
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import { useCategoryStore } from "@/stores/categoryStore";
import { useShoppingCartStore } from "@/stores/shoppingCartStore";

export default {
  name: "GlobalNavigation",
  data() {
    const userStore = useUserStore();
    const categoryStore = useCategoryStore();
    const shoppingCartStore = useShoppingCartStore();

    return {
      mobileMenuOpen: false,
      idFirstSelectedId: "",
      idSecondSelectedId: "",
      idThirdSelectedId: "",
      idFourthSelectedId: "",
      secondNavList: [],
      thirdNavList: [],
      fourthNavList: [],
      userStore,
      categoryStore,
      shoppingCartStore,
    };
  },
  async mounted() {
    await this.categoryStore.fetchNavList();
    await this.shoppingCartStore.fetchCartItemCount();
  },
  methods: {
    goToAllCategories() {
      router.push(`/categories`);
    },
    handleItemClick(id, closePopover) {
      closePopover();
      router.push(`/categories/${id}`);
    },
    async firstNavListChange(idToFind) {
      this.idFirstSelectedId = idToFind;
      this.idSecondSelectedId = "";
      this.idThirdSelectedId = "";
      this.idFourthSelectedId = "";
      this.thirdNavList = [];
      this.fourthNavList = [];
      const foundItem = this.categoryStore.navList.find(
        (item) => item.id === idToFind
      );
      this.secondNavList = foundItem.children;
    },
    async secondNavListChange(idToFind) {
      this.idSecondSelectedId = idToFind;
      this.idThirdSelectedId = "";
      this.idFourthSelectedId = "";
      this.fourthNavList = [];
      const foundItem = this.categoryStore.navList
        .flatMap((item) => item.children)
        .find((item) => item.id === idToFind);
      this.thirdNavList = foundItem.children;
    },
    async thirdNavListChange(idToFind) {
      this.idThirdSelectedId = idToFind;
      this.idFourthSelectedId = "";
      const foundItem = this.categoryStore.navList
        .flatMap((item) => item.children)
        .flatMap((item) => item.children)
        .find((item) => item.id === idToFind);
      this.fourthNavList = foundItem.children;
    },
    async fourthNavListChange(idToFind) {
      this.idFourthSelectedId = idToFind;
    },
    navigate(link) {
      router.push(link);
    },
    async logout() {
      await this.userStore.logout();
      router.push(`/login`);
    },
    async quotation() {
      router.push(`/quotation`);
    },
    async search() {
      router.push(`/search`);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-white {
  color: white;
}

.vw-75 {
  width: 75vw;
}

.mobile-header {
  z-index: 999;
}

.header {
  background: var(--primary-purple);
  width: 100%;
  z-index: 998;
}

.hover-effect {
  cursor: pointer;
  color: white;
  transition: var(--transition-fast);
}

.hover-effect:hover {
  color: whitesmoke;
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 25px;
}

.hover-effect-black {
  cursor: pointer;
  color: black;
  transition: var(--transition-fast);
}

.hover-effect-black:hover {
  color: var(--primary-purple);
}

.primary-color {
  color: var(--primary-purple);
}

.navItem {
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
}

.navItem:hover {
  background-color: var(--primary-purple);
  color: white;
}

.activenavItem {
  background-color: var(--primary-purple);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary-purple) !important;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: whitesmoke;
  border-radius: 10px;
}

.custom-a{
  color: var(--primary-purple);
  cursor: pointer;
}

.custom-a:hover{
  color: rgba(112, 48, 160, 0.7); /* 10% opacity */
}
</style>
