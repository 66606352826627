<template>
  <v-card-text>
    <v-form @submit.prevent="updatePanel">
      <v-card-title>Downloading the 2FA App</v-card-title>
      <v-card-text
        >
        You can skip this step if you already have a 2FA app installed such as
        Google Authenticator, Microsoft Authenticator...  <br> Download Google Authenticator by scanning the QR-code. </v-card-text
      >

      <v-col>
        <div class="store-badge-container mt-3">
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
          >
            <img
              src="../../../assets/img/apple-store-badge.svg"
              alt="App Store Logo"
            />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US"
            target="_blank"
          >
            <img
              src="../../../assets/img/google-play-badge.png"
              alt="Google Play Logo"
            />
          </a>
        </div>

        <div class="app-qr-container">

        </div>

        <hr />
        <div class="button-container-custom">
          <v-btn id="actionBtn" rounded="xl" elevation="5" type="submit"
            >Skip</v-btn
          >
          <span
            >Don't know how this app works?
            <a href="/tutorial/google-authenticator" target="_blank"
              >Tutorial</a
            ></span
          >
        </div>
      </v-col>
    </v-form>
  </v-card-text>
</template>

<script>
export default {
  name: "DownloadPanel",
  emits: ["continue"],
  methods: {
    updatePanel() {
      this.$emit("continue");
    },
  },
};
</script>

<style lang="scss" scoped src="../styles.scss" />
