<template>
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <img src="../../assets/img/logo.webp" alt="Caminos Logo" />
        <v-card rounded="xl" elevation="15" class="mx-auto" style="max-width: 600px;">
            <v-window disabled v-model="step">
                <v-window-item :value="1">
                    <CredentialsPanel @continue="next" v-on:updatePanel="updateCredentialsPanel" />
                </v-window-item>

                <v-window-item :value="2">
                    <PasswordPanel @continue="register" @back="previous" v-on:updatePanel="updatePasswordPanel" />
                </v-window-item>

                <v-window-item :value="3">
                    <DownloadPanel @continue="next" />
                </v-window-item>

                <v-window-item :value="4">
                    <SecretPanel @continue="finish" @back="previous" />
                </v-window-item>
            </v-window>
        </v-card>
    </section>

    <confirm-alert :title="'Do you want to leave?'"
        :message="'If you have scanned the QR code with Google Authenticator you are good to go.'" @cancel="stay"
        @confirm="leave" ref="confirmAlert"></confirm-alert>
</template>

<script>
import router from '@/router';
import { useUserStore } from '@/stores/userStore';
import { useRegisterSessionStore } from './registerSessionStore';
import CredentialsPanel from './panels/CredentialsPanel.vue';
import PasswordPanel from './panels/PasswordPanel.vue';
import DownloadPanel from './panels/DownloadPanel.vue';
import SecretPanel from './panels/SecretPanel.vue';

export default {
    name: "RegisterView",
    components: {
        CredentialsPanel,
        PasswordPanel,
        DownloadPanel,
        SecretPanel
    },
    data() {
        const userStore = useUserStore();
        const sessionStore = useRegisterSessionStore();

        return {
            userStore,
            sessionStore,
            step: 1,
            firstName: '',
            lastName: '',
            country: 0,
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            selectedTo: null,
            selectedFrom: null,
            selectedNext: null
        }
    },
    async mounted() {
        await this.sessionStore.getData();
        await this.setPanel();
    },
    beforeRouteLeave(to, from, next) {
        if (this.sessionStore.qr) {
            this.askConfirmation(to, from, next);
        } else {
            this.sessionStore.clearData();
            next();
        }
    },
    methods: {
        askConfirmation(to, from, next) {
            this.selectedTo = to;
            this.selectedFrom = from;
            this.selectedNext = next;
            this.$refs.confirmAlert.toggle();
        },
        stay() {
            this.selectedNext(false);
        },
        leave() {
            this.sessionStore.clearData();
            this.selectedNext();
        },
        async setPanel() {
            this.step = parseInt(this.sessionStore.step);
            this.firstName = this.sessionStore.firstName;
            this.lastName = this.sessionStore.lastName;
            this.country = parseInt(this.sessionStore.country);
            this.username = this.sessionStore.username;
            this.email = this.sessionStore.email;
        },
        next() {
            this.step++;
            this.sessionStore.setStep(this.step);
        },
        previous() {
            this.step--;
            this.sessionStore.setStep(this.step);
        },
        async register() {
            this.$root.toggleLoading();

            const credentials = {
                email: this.email,
                secret: this.secret,
                password: this.password,
                confirmPassword: this.confirmPassword
            };

            const response = await this.userStore.register(credentials);
            if (response.valid) {
                this.sessionStore.clearData();
                this.sessionStore.setQr(response.qr);
                this.next();
            } else {
                this.$root.handleAlert(response.alert.text, response.alert.timeout, response.alert.background);
            }

            this.$root.toggleLoading();
        },
        finish() {
            router.push('/login');
        },
        updateCredentialsPanel({ email }) {
            this.email = email;
            this.sessionStore.setCredentials(this.email);
        },
        updateUserPanel({ username, firstName, lastName, country }) {
            this.username = username;
            this.firstName = firstName;
            this.lastName = lastName;
            this.country = country;
            this.sessionStore.setUserInfo(username, firstName, lastName, country);
        },
        updatePasswordPanel({ password, confirmPassword }) {
            this.password = password;
            this.confirmPassword = confirmPassword;
        }
    },
    computed: {
        secret() {
            return this.$route.params.secret;
        }
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    width: 92.5%;
}

img {
    max-height: 100px;
    margin-bottom: 50px;
}
</style>