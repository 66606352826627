import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage'
import CategoryPage from '@/views/CategoryPage'
import CategoryDetailPage from '@/views/CategoryDetailPage'
import ProductPage from '@/views/ProductPage.vue'
import Login from '@/views/login/Login.vue'
import Register from '@/views/register/Register.vue'
//import ContactPage from '@/views/ContactPage.vue'
import { useUserStore } from '@/stores/userStore'
import SearchPage from '@/views/SearchPage.vue'
import GoogleAuthDownload from '@/views/download/AuthenticatorDownload.vue'
import GoogleAuthTutorial from '@/views/tutorial/AuthenticatorTutorial.vue'
import Quotation from '@/views/shoppingCart/shoppingCartPage.vue'
import NotFound from '@/components/error/NotFound.vue'


const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    {
        path: '/categories',
        name: 'categories',
        component: CategoryPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    {
        path: '/categories/:id',
        name: 'categoryDetail',
        component: CategoryDetailPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    {
        path: '/products/:id',
        name: 'products',
        component: ProductPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    {
        path: '/products/:id/:categoryid',
        name: 'productsCategory',
        component: ProductPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    
    /*
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },*/
    {
        path: '/search',
        name: 'search',
        component: SearchPage,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    },
    {
        path: '/quotation',
        name: 'quotation',
        component: Quotation,
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const response = await userStore.authorize();
            userStore.getData();
            if (response.valid) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
      },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register/:secret',
        name: 'register',
        component: Register
    },
    {
        path: '/download/google-authenticator',
        name: 'AuthenticatorDownload',
        component: GoogleAuthDownload
      },
      {
        path: '/tutorial/google-authenticator',
        name: 'AuthenticatorTutorial',
        component: GoogleAuthTutorial
      },
    
      {
        path: '/:catchAll(.*)',
        name: 'notFound',
        component: NotFound
      },
     

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router