import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loadingStore', {
    state: () => ({
        loading: false,
        counter: 0,
        action: ''
    }),
    actions: {
        start(action) {
            this.loading = true;
            this.action = action;
        },
        stop() {
            this.loading = false;
            this.counter = 0;
            this.action = '';
        }
    }
});