<template>
    <v-card-text>
        <v-form @submit.prevent="updatePanel">
            <v-card-title>Account Successfully Created</v-card-title>
            <v-card-text>Scan the following QR-code with your authenticator.</v-card-text>

            <v-col>
                <div class="app-qr-container">
                    <img :src="qr" alt="Secret QR-code">
                </div>
                <hr>
                <v-row>
                    <v-col cols="6">
                        <v-btn id="cancelBtn" rounded="xl" elevation="5" @click="cancel">Back</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn id="actionBtn" rounded="xl" elevation="5" type="submit">I'm ready</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-form>
    </v-card-text>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useRegisterSessionStore } from '../registerSessionStore';

export default {
    name: "SecretPanel",
    emits: ['continue', 'back'],
    data() {
        const userStore = useUserStore();
        const sessionStore = useRegisterSessionStore();

        return {
            userStore,
            sessionStore,
            qr: ''
        }
    },
    async mounted() {
        await this.sessionStore.getData();
        await this.setPanel();
    },
    methods: {
        async setPanel() {
            this.qr = this.formatImg(this.sessionStore.qr);
        },
        updatePanel() {
            this.$emit("continue");
        },
        cancel() {
            this.$emit("back");
        },
        formatImg(img) {
            return `data:image/png;base64,${img}`;
        }
    }
}
</script>

<style lang="scss" scoped src="../styles.scss"/>