<template>
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <img src="../../assets/img/logo.webp" alt="Caminos Logo" />
        <h3>Page Not Found.</h3>
        <span>Sorry, we can't find the page you're looking for.</span>
        <v-btn id="actionBtn" rounded="xl" elevation="5" @click="navigate(`/login`)">Back To Login</v-btn>
    </section>
</template>

<script>
import router from '@/router';

export default {
    name: "NotFound",
    methods: {
        navigate(link) {
            router.push(link)
        }
    }
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

img {
    max-height: 100px;
    margin-bottom: 50px;
}
</style>