<template>
  <main id="category" class="flex flex-col min-h-screen">
    <NavigationBar />
    <CategorySection class="flex-grow mt-5"
      :id="urlId"
      :breadcrumbs="categoryStore.item.parents"
      :description="categoryStore.item.name"
      :products="products"
      :categories="categories"
      :pages="pages"
      v-on:updatePanel="updatePanel"
    />
    <FooterSection class="footer" />
  </main>
</template>

<script>
import NavigationBar from "@/components/navigation/NavigationBar.vue";
import CategorySection from "@/components/categories/CategorySection";
import FooterSection from "@/components/footer/FooterSection.vue";
import { useCategoryStore } from "@/stores/categoryStore";

export default {
  name: "CategoryDetailPage",
  components: {
    NavigationBar,
    CategorySection,
    FooterSection,
  },
  data() {
    const categoryStore = useCategoryStore();

    return {
      categoryStore,
      products: [],
      categories: [],
      children: [],
      page: 1,
      pages: 1,
    };
  },
  watch: {
    "$route.params.id": {
      handler: "fetch",
      immediate: true,
    },
    '$route'() {
        this.fetch();
    }
  },
  methods: {
    updatePanel({ page }) {
      this.page = page;
      this.fetch();
    },
    async fetch() {
      await this.categoryStore.fetchById(this.urlId, this.page);
      this.products = this.categoryStore.item.products;
      this.categories = this.categoryStore.item.children;
      this.pages = this.categoryStore.pages;
    },
  },
  computed: {
    urlId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.footer {
    display: absolute !important;
    bottom: 0 !important;
}
</style>
