<template>
    <v-dialog v-model="show" persistent max-width="500px">
        <v-card rounded="xl">
            <v-card-text>
                <v-card-title>
                    <span class="material-icons">warning</span>
                    <span>{{ title }}</span>
                </v-card-title>
                <v-card-text class="text-center">{{ message }}</v-card-text>

                <v-col>
                    <v-row>
                        <v-col cols="6">
                            <v-btn rounded="xl" class="custom-btn-nr" id="cancelBtn" @click="cancel">
                                <span class="material-icons-outlined">cancel</span>
                                <span class="btn-text">Cancel</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn rounded="xl" class="custom-btn-nr" id="confirmBtn" @click="confirm">
                                <span class="material-icons-outlined">check_circle</span>
                                <span class="btn-text">Confirm</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmAlert",
    emits: ['cancel', 'confirm'],
    props: {
        title: {
            type: String,
            default: 'WARNING!'
        },
        message: {
            type: String,
            default: 'Do you want to continue?'
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        toggle() {
            this.show = !this.show;
        },
        cancel() {
            this.toggle();
            this.$emit('cancel');
        },
        confirm() {
            this.toggle();
            this.$emit('confirm');
        }
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    background-color: rgba(255, 255, 255, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    box-shadow: var(--shadow-strong);
}

.v-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}

.v-card-title .material-icons {
    color: red;
    font-size: xx-large;
}

.v-btn {
    width: 100%;
    max-width: 100% !important;
}
</style>