<template>
    <footer id="footer" class="footer">

        <div class="container">
            <div class="row gy-3">
                <div class="col-lg-6 col-md-6 footer-links d-flex">
                    <i class="bi bi-envelope icon"></i>
                    <div>
                        <h4>Contact Information</h4>
                        <p>
                            <strong>Email:</strong> info@caminos-online.com<br>
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 footer-links">
                    <h4>Other Links</h4>
                    <div class="social-links d-flex">
                        <a href="https://www.caminos-online.com" target="_blank"><i class="bi bi-globe"></i></a>
                        <a href="https://www.caminos-online.com/blog.html" target="_blank"><i class="bi bi-chat-left-text"></i></a>
                    </div>
                </div>

            </div>
        </div>

        <div class="container">
            <div class="copyright">
                &copy; Copyright <strong><span>Caminos</span></strong>. All Rights Reserved
            </div>

        </div>

    </footer>
</template>

<script>
export default {
    name: "FooterSection"
}
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    margin-top: auto;
    font-size: 14px;
    background: #7030A0;
    padding: 50px 0;
    color: rgba(255, 255, 255, 0.7);
}

.footer .icon {
    margin-right: 15px;
    font-size: 24px;
    line-height: 0;
}

.footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
    color: #fff;
}

.footer .footer-links {
    margin-bottom: 30px;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

.footer .footer-links ul a:hover {
    color: #fff;
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 10px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: #fff;
    border-color: #fff;
}

.footer .copyright {
    text-align: center;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .credits {
    padding-top: 4px;
    text-align: center;
    font-size: 13px;
}

.footer .credits a {
    color: #fff;
}
</style>