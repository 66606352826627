<template>
  <router-view class ="min-height"  @contextmenu.prevent/>
  <snack-alert @contextmenu.prevent :timeout="timeout" :text="text" :background="background" ref="alert" />
  <loading-screen @contextmenu.prevent :counter="counter" :actions="actions" ref="loading" />
  <loading-screen-store @contextmenu.prevent :loading="storeLoad" :counter="storeCount" :actions="storeAction" />
</template>

<script>
import { useLoadingStore } from '@/stores/loadingStore'

export default {
  name: 'App',
  data() {
    const loadingStore = useLoadingStore();

    return {
      loadingStore,
      timeout: 0,
      text: '',
      background: '',
      counter: false,
      actions: false
    }
  },
  methods: {
    handleAlert(text, timeout = 3000, background = 'green') {
      this.timeout = timeout;
      this.text = text;
      this.background = background;
      this.$refs.alert.toggle();
    },
    toggleLoading() {
      this.$refs.loading.toggle();
    },
    setLoadingMode() {
      this.counter = true;
      this.actions = true;
    },
    resetLoadingMode() {
      this.counter = false;
      this.actions = false;
    },
    increaseLoading(num) {
      this.$refs.loading.addValue(num);
    },
    setAction(action) {
      this.$refs.loading.setAction(action);
    }
  },
  computed: {
    visible() {
      return this.$route.name != "login" && this.$route.name != "register" && this.$route.name != "notFound" && this.$route.name != "AuthenticatorDownload" && this.$route.name != "AuthenticatorTutorial";
    },
    storeLoad() {
      return this.loadingStore.loading;
    },
    storeCount() {
      return this.loadingStore.counter;
    },
    storeAction() {
      return this.loadingStore.action;
    }
  }
}
</script>

<style lang="scss">

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

:root {
  /* Color Codes */
  --primary-purple: #7030A0;
  --primary-blue: rgb(0, 132, 255);
  --primary-green: green;
  --primary-red: red;
  --primary-grey: grey;
  --primary-light-grey: lightgrey;
  --primary-black: black;

  --faded-black: rgba(0, 0, 0, 0.7);
  --faded-green: rgba(0, 160, 40, 0.7);
  --faded-red: rgba(255, 0, 0, 0.7);
  --faded-purple: rgba(101, 44, 127, 0.7);
  --faded-blue: rgba(0, 0, 255, 0.7);

  --light-purple: rgba(210, 140, 255, 0.7);

  /* Transitions */
  --transition-fast: all 0.3s ease;
  --transition-normal: all 0.5s ease;
  --transition-slow: all 0.7s ease;

  /* Box Shadows */
  --shadow-weak: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --shadow-medium: 2px 4px 6px rgba(0, 0, 0, 0.2);
  --shadow-strong: 4px 6px 12px rgba(0, 0, 0, 0.2);

  --shadow-weak-white: 0px 2px 4px rgba(255, 255, 255, 0.2);
  --shadow-medium-white: 2px 4px 6px rgba(255, 255, 255, 0.2);
  --shadow-strong-white: 4px 6px 12px rgba(255, 255, 255, 0.2);

  /* Transitions */
  --transition-fast: all 0.3s ease;
}

* {
  font-family: 'Poppins', sans-serif;
}

.main {
  height: 100%;
}

/* CONTAINERS */
.button-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* BUTTONS */
.custom-btn,
.custom-btn-nr {
  width: 100%;
  max-width: 140px !important;

  .btn-text {
    margin-left: 7.5px;
  }
}

@media (max-width: 1000px) {
  .custom-btn {
    width: 100%;
    max-width: 90px !important;

    .btn-text {
      display: none;
    }
  }
}

#editBtn,
#actionBtn {
  color: white;
  background-color: var(--primary-purple);
}

#editBtn:hover,
#actionBtn:hover {
  color: var(--primary-purple);
  background-color: transparent;
  border: 2px solid var(--primary-purple);
}

#alternativeBtn {
  color: white;
  background-color: var(--primary-grey);
}

#alternativeBtn:hover {
  color: var(--primary-grey);
  background-color: transparent;
  border: 2px solid var(--primary-grey);
}

#actionBtn-outlined {
  color: var(--primary-purple);
  background-color: transparent;
  border: 2px solid var(--primary-purple);
}

#actionBtn-outlined:hover {
  background-color: var(--light-purple);
  border: 2px solid var(--primary-purple);
}

#filterBtn,
#backBtn {
  color: white;
  background-color: var(--primary-black);
}

#filterBtn:hover,
#backBtn:hover {
  color: var(--primary-black);
  background-color: transparent;
  border: 2px solid var(--primary-black);
}

#saveBtn,
#confirmBtn,
#addBtn {
  color: white;
  background-color: var(--primary-green);
}

#saveBtn:hover,
#confirmBtn:hover,
#addBtn:hover {
  color: var(--primary-green);
  background-color: transparent;
  border: 2px solid var(--primary-green);
}

#cancelBtn,
#deleteBtn {
  color: white;
  background-color: var(--primary-red);
}

#cancelBtn:hover,
#deleteBtn:hover {
  color: var(--primary-red);
  background-color: transparent;
  border: 2px solid var(--primary-red);
}

#deleteIcon,
#addIcon {
  transition: var(--transition-fast);
}

#deleteIcon:hover {
  color: var(--primary-red);
}

#addIcon:hover {
  color: var(--primary-green);
}

#downloadIcon,
#downloadAllIcon {
  transition: var(--transition-fast);
}

#downloadAllIcon {
  font-size: 25px;
}

#downloadIcon:hover,
#downloadAllIcon:hover {
  color: var(--primary-blue);
}

/* TEXT STYLES */
.warning-text {
  color: var(--primary-red);
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  opacity: 0.7;
}

body {
  min-height: 100vh !important;
}
</style>
