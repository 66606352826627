<template>
    <main id="product" class="product flex flex-col min-h-screen">
        <NavigationBar />
        <ProductDetailSection class="flex-grow" :images="images" :videos="videos" :specifications="specifications" :productId="productId" :files="files"
            :pdfs="pdfs" :breadcrumbs="parents" :description="currentCategoryName" :quotation="quotation" />
            <br>
        <FileSection v-if="pdfs.length > 0" />
        <FooterSection />
    </main>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import ProductDetailSection from '@/components/products/ProductDetailSection';
import FileSection from '@/components/files/FileSection.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useProductStore } from '@/stores/productStore';

export default {
    name: 'HomePage',
    components: {
        NavigationBar,
        ProductDetailSection,
        FileSection,
        FooterSection
    },
    data() {
        const productStore = useProductStore();

        return {
            description: 'Discover our product',
            title: 'Product Detail',
            quotation: false,
            currentCategoryName: '',
            productStore,
            images: [],
            videos: [],
            specifications: [],
            productId: '',
            files: [],
            pdfs: [],
            parents: []
        }
    },
    watch: {
        '$route.params.id': {
            handler: 'fetch',
            immediate: true
        }
    },
    methods: {
        async fetch() {
            await this.productStore.fetchById(this.urlId, this.categoryId);
            let product = this.productStore.item;
            this.parents = this.productStore.item.parents;
            this.currentCategoryName = this.productStore.item.name;
            let coImage = {
                image: product.coverImage,
            }
            this.images.push(coImage);
            await Promise.all(product.images.map(async img => {
                let base64 = await this.productStore.fetchImageById(this.urlId, img);
                this.images.push(base64);
            }));

            await Promise.all(product.videos.map(async vid => {
                let video = await this.productStore.fetchVideoById(this.urlId, vid);
                this.videos.push(video);
            }));

            this.images.sort((a, b) => a.order - b.order);

            this.specifications = product.specifications;
            this.productId = product.productId;
            this.files = product.files;
            this.pdfs = product.pdfs;
            this.quotation = product.hasQuotation;
        }
    },
    computed: {
        urlId() {
            return this.$route.params.id;
        },
        categoryId() {
            return this.$route.params.categoryid;
        }
    }
}
</script>

<style lang="scss" scoped>
.product {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
</style>