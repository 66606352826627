<template>
  <div class="bg-white">
    <div
      class="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8"
    >
      <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          PDF Viewer
        </h2>
        <p class="mt-4 text-gray-500">
          If you're not seeing PDF previews, consider using a different browser.
          In our experience, Safari and Chrome work best.
        </p>
      </div>

      <div class="row">
        <div class="col-md-4 mb-5">
          <div>
            <nav class="flex flex-1 flex-col" aria-label="Sidebar">
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="(item, index) in productStore.pdfs" :key="item.id">
                  <span
                    @click="updateFile(item.id, index)"
                    :class="[
                      item.current
                        ? 'bg-gray-50 text-purple-600'
                        : 'text-gray-700 hover:text-purple-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold cursor-pointer',
                    ]"
                    >{{ item.fileName }}</span
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="col-md-8">
          <button class="btn w-100 custom-button" @click="openPDF(this.currentFile)">If PDF not visible, click here</button>
          <br />
          <br />
          <p class="text-center">This is a preview, not a download.</p>

          <iframe class="pdf-viewer" :src="currentFile"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useProductStore } from "@/stores/productStore";

export default {
  name: "FileSection",
  data() {
    const productStore = useProductStore();

    return {
      productStore,
      currentFile: "",
    };
  },
  watch: {
    "productStore.pdfs": {
      handler: "setFirst",
      immediate: true,
    },
  },
  methods: {
    formatPdf(img) {
      return `data:application/pdf;base64,${img}`;
    },
    async updateFile(id, index) {
      this.productStore.pdfs.forEach((pdf) => {
        pdf.current = false;
      });
      this.productStore.pdfs[index].current = true;

      this.currentFile = this.formatPdf(
        await this.productStore.fetchPdfById(this.urlId, id)
      );
    },
    async setFirst() {
      let pdf = this.productStore.pdfs[0];
      pdf.current = true;

      this.currentFile = this.formatPdf(
        await this.productStore.fetchPdfById(this.urlId, pdf.id)
      );
    },
    openPDF() {
      const base64PDF = this.currentFile.split(
        "data:application/pdf;base64,"
      )[1];

      const byteCharacters = atob(base64PDF);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, "_blank");
    },
  },
  computed: {
    urlId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss" scoped>


.custom-button {
  color: white;
  background-color: #7030a0;
}


.custom-button:hover {
  color: #7030a0;
  background-color: white;
  border: solid 1px #7030a0;
}

.cta {
  background: linear-gradient(
      45deg,
      rgba(#000000, 0.8) 0%,
      rgba(#000000, 0.6) 100%
    ),
    url("../../assets/img/building-temp.jpg") fixed center center;
  background-size: cover;
  padding: 60px;
  height: 250px;

  img {
    width: 100%;
    max-width: 500px;
  }
}

.row {
  margin-top: 75px;
}

.pdf-viewer {
  width: 100%;
  height: 80vh;
}

.href-link {
  color: #9333ea;
  cursor: pointer;
}
</style>
